var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"models-table-scenarios archi-table mt-5",on:{"click":function($event){return _vm.resetSelectedItems()}}},[(_vm.isLoading)?_c('div',{staticClass:"d-flex justify-center"},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1):_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-left"},[_vm._v("Name")]),_c('th',{staticClass:"text-left"},[_vm._v("Type")]),_c('th',{staticClass:"text-left"},[_vm._v("Status")]),_c('th',{staticClass:"text-left"},[_vm._v("Date created")]),_c('th',{staticClass:"text-left"},[_vm._v("Created by")]),_c('th',{staticClass:"text-left"},[_vm._v("Shared with")]),_c('th',{staticClass:"text-left"})])]),_c('tbody',[_vm._l((_vm.scenarios),function(quarter,indexQuarter){return [_c('tr',{key:'Quarter_' + indexQuarter},[_c('td',{staticClass:"d-flex align-center"},[_c('span',{staticClass:"title-quarter"},[_vm._v(_vm._s(quarter.quarter))]),_c('img',{staticClass:"ml-3 cursor-pointer",attrs:{"src":require("@/assets/icons/models/settings.svg"),"alt":""},on:{"click":function($event){$event.stopPropagation();return _vm.showMasterSettings(quarter.quarter)}}})]),_c('td'),_c('td'),_c('td'),_c('td'),_c('td'),_c('td')]),_vm._l((quarter.projects),function(project,indexProject){return [_c('tr',{key:'Quarter_' +
                indexQuarter +
                '_project_' +
                project.project +
                '_' +
                indexProject},[_c('td',{staticClass:"d-flex align-center"},[_c('div',{staticClass:"cursor-pointer mr-3",on:{"click":function($event){project.expand = !project.expand}}},[(project.expand)?_c('img',{attrs:{"src":require("@/assets/icons/models/arrow-down.svg"),"alt":""}}):_c('img',{attrs:{"src":require("@/assets/icons/models/arrow-right.svg"),"alt":""}})]),_c('span',[_vm._v(_vm._s(project.projectName))])]),_c('td',[_vm._v("-")]),_c('td',[_vm._v("-")]),_c('td',[_vm._v(_vm._s(_vm.moment(project.createdOn).format('DD/MM/YYYY')))]),_c('td',[_vm._v(_vm._s(project.createdBy))]),_c('td'),_c('td')]),(project.expand)?[_vm._l((project.datasets),function(dataset,indexDataset){return [_c('ScenariosTableRow',{key:'dataset_o_' + dataset.scenarioId + '_' + indexDataset,attrs:{"item":dataset,"rowType":"dataset","level":1,"indexes":{
                    indexQuarter: indexQuarter,
                    indexProject: indexProject,
                    indexDataset: indexDataset
                  }}}),(dataset.expand)?[_vm._l((dataset.baselines),function(baseline,indexBaseline){return [_c('ScenariosTableRow',{key:'baseline_o_' +
                        baseline.scenarioId +
                        '_' +
                        indexDataset +
                        '_' +
                        indexBaseline,attrs:{"item":baseline,"rowType":"baseline","level":2,"indexes":{
                        indexQuarter: indexQuarter,
                        indexProject: indexProject,
                        indexDataset: indexDataset,
                        indexBaseline: indexBaseline
                      }}}),(baseline.expand)?[_vm._l((baseline.scenarios),function(scenario,indexScenario){return [_c('ScenariosTableRow',{key:'scenario' +
                            scenario.scenarioId +
                            '_' +
                            indexDataset +
                            '_' +
                            indexBaseline +
                            '_' +
                            indexScenario,attrs:{"item":scenario,"rowType":"scenario","level":3,"indexes":{
                            indexQuarter: indexQuarter,
                            indexProject: indexProject,
                            indexDataset: indexDataset,
                            indexBaseline: indexBaseline,
                            indexScenario: indexScenario
                          }}})]})]:_vm._e()]})]:_vm._e()]})]:_vm._e()]})]})],2)]},proxy:true}])}),_c('CreateScenarioModal'),_c('RenameScenarioModal'),_c('DeleteScenarioModal'),_c('MasterSettingsModal')],1)}
var staticRenderFns = []

export { render, staticRenderFns }